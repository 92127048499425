import { useLazyQuery } from '@apollo/client';
import { Box, Checkbox, Fab, FormControl, FormControlLabel, Grid, MenuItem, Theme, Typography, CircularProgress, Alert } from '@mui/material'
import { Field, Form, Formik } from 'formik';
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect, useState } from 'react'
import { DateTime as d } from "luxon"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TextField as InputField } from "formik-mui";
import * as Yup from "yup";
import { setCreateBookingInput } from '../../../../../actions/bookings/actions';
import { GET_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getAvailableVehicleGroups';
import { GET_BUSINESS_CUSTOMERS } from '../../../../../graphql/businessCustomers/businessCustomer';
import { GET_BRANCH } from '../../../../../graphql/organisation/getBranch';
import { BOOKING_CATEGORY, IBookingBusinessCustomer, IBookingCreateInput, IBookingVehicleGroup } from '../../../../../reducers/bookings/types';
import { IVehicle } from '../../../../../reducers/fleet/types';
import { IServiceLocation } from '../../../../../reducers/user/types';
import { IVehiclePriceGroup } from '../../../../../reducers/vehiclePriceGroups/types';
import { IAppState } from '../../../../../store';
import { DateTimePicker } from '../../../../common/DateTimePicker';
import { toCurrency, formatGraphQLErrorMessage } from '../../../../common/utils';
import { NuvvenDivider } from '../../../ReservationManagement/Summary/Components/Divider';
import { capitalizeFirstLetter, customerTypes, getEndDateForBilling, getMileageLimitText, rateTypes, RATE_TYPES } from '../../../ReservationManagement/utils';
import { IPriceRule } from '../../../../../reducers/priceRule/types';
import { GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS } from '../../../../../graphql/bookings/getExternalAvailableVehicleGroups';
import { useSnackBar } from "../../../../common/SnackBarContext/SnackBarContext";
import { SnackBarVariant } from "../../../../common/SnackbarWrapper/SnackbarWrapper";
import { ApolloError } from '@apollo/client';
import { UserRoles } from '../../../../hoc/Authorization';
import { getLocalizedBookingSyntex } from '../../../../../utils/localized.syntex';
import { GET_SERVICE_LOCATIONS_IN_ORG } from '../../../../../graphql/vehicleMovement/queries';
import { ILocationSurchargeInput, ILocationTaxSurcharge } from '../../../../../reducers/organisation/types';
import { IOneWayRentalFee } from '../../../VehicleMovement/types';

const initialValues: IBookingCreateInput = {
  customerType: "individual",
  pickupServiceLocation: "",
  dropoffServiceLocation: "",
  pickupDateTime: "",
  dropoffDateTime: "",
  flightNumber: "",
  longTermBooking: false,
  rateTypeName: "daily",
  rateTypeDuration: 1440,
  vehicleGroups: [],
  insuranceRate: 0,
  excess: 0,
  addonRequirements: [],
  approvedDrivers: [],
  pcoNumber: false,
  locationSurcharges: [],
  isRecurringBilling: false,
  billingFrequency: 0,
  isCoi: false,
  coiDetails: {
    policyName: "",
    policyNumber: "",
    provider: "",
    expiryDate: "",
    documentUrl: ""
  },
  bookingCategory: BOOKING_CATEGORY.RENTAL
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      marginBottom: theme.spacing(2)
    },
  })
)

interface IDialogData {
  startDate?: string;
  endDate?: string;
  resource?: IVehicle;
}


interface IProps {
  type: string;
  handleClose(): void
  dialogData: IDialogData;
}

export const NewBookingDialog: React.FC<IProps> = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const snackbar = useSnackBar();
  const navigate = useNavigate()
  const { dialogData } = props
  const userState = useSelector((state: IAppState) => state.userReducer);
  const { locale, currency } = userState.currentOrganisation;
  const { country } = userState.currentOrganisation.address;
  const { distanceUnit } = userState.currentBranch;
  const remainder = 15 - (d.now().minute % 15);

  const [differentDropOffLocation, setDifferentDropOffLocation] = useState<boolean>(false);
  const [vehicleGroups, setVehicleGroups] = useState<IVehiclePriceGroup[]>([])
  const [values, setValues] = useState<IBookingCreateInput>(initialValues)
  // TODO: look into this later
  const [businessCustomer, setBusinessCustomer] = useState<IBookingBusinessCustomer | undefined>();
  const [locationSurchargeRules, setLocationSurchargeRules] = useState<IPriceRule[]>([]);
  const [rentalPrice, setRentalPrice] = useState<number>(0)
  const [serviceLocations, setServiceLocations] = useState<IServiceLocation[]>([]);
  const [locationSurchargeData, setLocationSurchargeData] = useState<ILocationTaxSurcharge[]>([])
  const [oneWayRentalFee, setOneWayRentalFee] = useState<IOneWayRentalFee>();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const isSharedFleet = userState.currentBranch.id === props.dialogData.resource?.branchId ? false : true;

  const [
    loadBranchData,
    { data: branchData }
  ] = useLazyQuery(GET_BRANCH, {
    fetchPolicy: "network-only",
    onCompleted: (branchData) => {
      if (branchData && branchData.branch && branchData.branch.activeLocations) {
        setServiceLocations(branchData.branch.activeLocations)
      }
    },
    variables: { branchId: userState.currentBranch.id }
  });

  const [loadServiceLocationInOrg, { loading: loadingServiceLocationInOrg }] = useLazyQuery(GET_SERVICE_LOCATIONS_IN_ORG, {
    fetchPolicy: "no-cache",
    onCompleted: (serviceLocationsData) => {
      if (serviceLocationsData && serviceLocationsData.getServiceLocations) {
        setServiceLocations(serviceLocationsData.getServiceLocations)
      }
    }
  });

  const [loadVehicleGroups,
    { loading: vehicleGroupsLoading,
      data: availableVehicleGroupsData }
  ] = useLazyQuery(
    GET_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    loadBusinessCustomers,
    { data: businessCustomersData }
  ] = useLazyQuery(GET_BUSINESS_CUSTOMERS, {
    fetchPolicy: "network-only",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  const [
    loadExternalVehicleGroups,
    { loading: externalVehicleGroupsLoading, data: externalAvailableVehicleGroupsData }
  ] = useLazyQuery(GET_EXTERNAL_AVAILABLE_VEHICLE_GROUPS, {
    fetchPolicy: "no-cache",
    onError: (error: ApolloError) => {
      snackbar({
        message: formatGraphQLErrorMessage(error.message),
        variant: SnackBarVariant.ERROR
      });
    }
  });

  useEffect(() => {
    loadBusinessCustomers()
  }, [])

  useEffect(() => {
    if (dialogData.startDate && dialogData.endDate) {
      setValues({
        ...values,
        pickupDateTime: dialogData.startDate || "",
        dropoffDateTime: dialogData.endDate,
        pcoNumber: dialogData.resource?.pcoNumber ? true : false,
      })
    }
  }, [dialogData.startDate, dialogData.endDate])

  useEffect(() => {
    const vehicleGroupsData = []
    const locationSurcharges = []
    if (props.dialogData.resource && props.dialogData.resource.branchId === userState.currentBranch.id) {
      if (availableVehicleGroupsData && availableVehicleGroupsData.availableVehicleGroups) {
        setOneWayRentalFee(availableVehicleGroupsData.availableVehicleGroups.oneWayRentalFee);
        vehicleGroupsData.push(...availableVehicleGroupsData.availableVehicleGroups.vehicleGroups)
        locationSurcharges.push(...availableVehicleGroupsData.availableVehicleGroups.locationSurcharges)
      }
    } else {
      if (externalAvailableVehicleGroupsData && externalAvailableVehicleGroupsData.externalAvailableVehicleGroups) {
        vehicleGroupsData.push(...externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.vehicleGroups)
        locationSurcharges.push(...externalAvailableVehicleGroupsData.externalAvailableVehicleGroups.locationSurcharges)
      }
    }
    setVehicleGroups(vehicleGroupsData)
    const surChargeData: ILocationTaxSurcharge[] = [];
    if (locationSurcharges && locationSurcharges.length) {
      locationSurcharges.forEach((surcharge: ILocationSurchargeInput) => {
        surChargeData.push({
          id: surcharge.id || "",
          name: surcharge.title,
          value: surcharge.value,
          rate: surcharge.rate,
          type: surcharge.type,
          tax: {
            title: surcharge.taxable ? surcharge.tax.title : "",
            value: surcharge.taxable ? surcharge.tax.value : 0
          }
        });
      })
    }
    if (surChargeData && surChargeData.length) {
      setLocationSurchargeData(surChargeData);
    }
  }, [availableVehicleGroupsData, externalAvailableVehicleGroupsData])

  useEffect(() => {
    if (userState && userState.currentBranch && userState.currentBranch.id) {
      if (userState.currentOrganisation.crossLocationBookingEnabled) {
        loadServiceLocationInOrg({
          variables: {
            organisationId: userState.currentOrganisation.id
          }
        })
      } else {
        loadBranchData({
          variables: {
            branchId: userState.currentBranch.id
          }
        });
      }
    }
  }, [userState]);

  useEffect(() => {
    if (values.dropoffDateTime || values.pickupDateTime) {
      const weeklyEndDate = getEndDateForBilling(values.pickupDateTime, RATE_TYPES.WEEKLY);
      if (values.dropoffDateTime && values.dropoffDateTime >= weeklyEndDate) {
        setValues({
          ...values,
          rateTypeDuration: 10080,
          rateTypeName: RATE_TYPES.WEEKLY
        });
      } else {
        if (values.rateTypeName === RATE_TYPES.WEEKLY || values.rateTypeName === RATE_TYPES.MONTHLY) {
          setValues({
            ...values,
            rateTypeDuration: 1440,
            rateTypeName: RATE_TYPES.DAILY
          })
        }
      }
    }
  }, [values.dropoffDateTime, values.pickupDateTime])

  useEffect(() => {
    if (
      businessCustomersData &&
      businessCustomersData.businessCustomers &&
      values.businessCustomer &&
      values.id
    ) {
      const businessCustomerData = businessCustomersData.businessCustomers.find(
        (customer: IBookingBusinessCustomer) => {
          return customer.id === values.businessCustomer;
        }
      );
      setBusinessCustomer(businessCustomerData);
    }
  }, [businessCustomersData]);

  const getAvailableVehicleGroups = (startDate: string, endDate: string) => {
    setValues({
      ...values,
      pickupDateTime: startDate,
      dropoffDateTime: endDate,
    })
    const isInvalidDate = d.fromISO(endDate || "") <= d.fromISO(startDate)
    if (isInvalidDate) {
      setErrorMessage("Please select valid pickup and drop-off date time.")
    } else {
      setErrorMessage("")
      if (values.pickupServiceLocation) {
        loadVehicleGroups({
          variables: {
            args: {
              endDate: endDate,
              startDate: startDate,
              vehicleId: dialogData.resource?.id,
              rateTypeDuration: values.rateTypeDuration,
              businessCustomer: businessCustomer && businessCustomer.id ? businessCustomer.id : "",
              longTerm: false,
              serviceLocation: values.pickupServiceLocation
            }
          }
        })
        loadExternalVehicleGroups({
          variables: {
            args: {
              endDate: endDate,
              startDate: startDate,
              vehicleId: dialogData.resource?.id,
              rateTypeDuration: values.rateTypeDuration,
              businessCustomer: businessCustomer && businessCustomer.id ? businessCustomer.id : "",
              longTerm: false,
              serviceLocation: values.pickupServiceLocation
            }
          }
        })
      }
    }
  }

  const baseDetailsSchema = Yup.object().shape({
    dropoffServiceLocation: Yup.string(),
    flightNumber: Yup.string(),
    pickupDateTime: Yup.string()
      .min(1)
      .required("Please select dropoff location"),
    pickupServiceLocation: Yup.string()
      .required("Please select pickup location")
      .min(1),
  });

  const activateProceedButton = !values.pickupServiceLocation ||
    !values.pickupDateTime ||
    (!values.billingCycleName && !values.dropoffDateTime) ||
    (differentDropOffLocation && !values.dropoffServiceLocation) ||
    values.vehicleGroups.length <= 0 ||
    (values.customerType === "business" &&
      (Boolean(!values.businessCustomer) ||
        Boolean(!values.authorizedSignatory)))

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validationSchema={baseDetailsSchema}
        initialValues={values}
        onSubmit={(values, { setSubmitting }) => {
          values.locationSurcharges = locationSurchargeData
          const pickup = d.fromISO(values.pickupDateTime);
          if (userState.role !== UserRoles.SUPER_ADMIN) {
            if (pickup < d.now()) {
              snackbar({
                message: "Pick-up date/time should be in future.",
                variant: SnackBarVariant.ERROR
              });
              return;
            }
            if (values.dropoffDateTime && pickup > d.fromISO(values.dropoffDateTime)) {
              snackbar({
                message: "Drop-off time should be greater than pick-up time.",
                variant: SnackBarVariant.ERROR
              });
              return;
            }
          }
          if (!differentDropOffLocation) {
            values.dropoffServiceLocation = values.pickupServiceLocation;
          }
          dispatch(setCreateBookingInput(values))
          navigate("/new-booking?step=1")
        }}
      >
        {(formProps) => (
          <Form className={classes.form}>
            <Grid container item xs={12}>
              <NuvvenDivider noMargin={true} />
              <Grid container item xs={12}><Box mt={2}></Box></Grid>
              <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12}>
                  <Grid item xs={4} sm={2}>
                    <Typography variant={"h3"}>{"VEHICLE"}</Typography>
                  </Grid>
                  <Grid container item xs={8} sm={10} alignItems={"center"}>
                    <Typography variant={"body1"}>{`${dialogData.resource?.make.toUpperCase()} | ${dialogData.resource?.licencePlate}`}</Typography>
                  </Grid>
                </Grid>
                {(values.pickupDateTime && values.dropoffDateTime) && (
                  <Grid container item xs={12} spacing={2}>
                    <DateTimePicker
                      key={values.pickupDateTime}
                      fromDateTime={values.pickupDateTime || ""}
                      toDateTime={values.dropoffDateTime || ""}
                      handleChange={getAvailableVehicleGroups}
                      required={true}
                      labels={values.tba ? ["Pick-up Date", "Pick-up Time", "Renewal Date", "Renewal Time"] : ["Pick-up Date", "Pick-up Time", "Drop-off Date", "Drop-off Time"]}
                      gridSpan={6}
                      minDateTime={d.now().toUTC().toISO()}
                    />
                  </Grid>
                )}
                {
                  errorMessage && (
                    <Grid item xs={12}>
                      <Alert variant="filled" severity="error">{errorMessage}</Alert>
                    </Grid>
                  )
                }
              </Grid>
              <Grid container item xs={12}>
                <Box mt={2}></Box>
              </Grid>
              <NuvvenDivider noMargin={true} />
              <Grid container item xs={12}>
                <Box mb={2}></Box>
              </Grid>
              {
                !errorMessage && (
                  <Grid container item xs={12}>
                    <Grid container item xs={12} spacing={2}>
                      <Grid container item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={InputField}
                            name={"customerType"}
                            fullWidth
                            required
                            type="text"
                            select
                            label="Customer Type"
                            inputProps={{
                              onChange: (event: any) => {
                                if (event.target.value === "individual") {
                                  setValues({
                                    ...values,
                                    authorizedSignatory: "",
                                    businessCustomer: "",
                                    customerType: event.target.value,
                                  });
                                } else {
                                  setValues({
                                    ...values,
                                    customerType: event.target.value
                                  });
                                }
                              },
                              value: values.customerType
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                          >
                            {customerTypes.map((item: any, index: number) => {
                              return (
                                <MenuItem key={index} value={item.value}>
                                  {item.label}
                                </MenuItem>
                              );
                            })}
                          </Field>
                        </FormControl>
                      </Grid>
                      {values.customerType === "business" && (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={InputField}
                                name={"businessCustomer"}
                                required
                                fullWidth
                                type="text"
                                select
                                label="Select Business Account"
                                inputProps={{
                                  onChange: (event: any) => {
                                    if (event && event.target && event.target.value) {
                                      if (
                                        businessCustomersData &&
                                        businessCustomersData.businessCustomers
                                      ) {
                                        const businessCustomerData = businessCustomersData.businessCustomers.find(
                                          (customer: IBookingBusinessCustomer) => {
                                            return customer.id === event.target.value;
                                          }
                                        );
                                        setBusinessCustomer(businessCustomerData);
                                        setValues({
                                          ...values,
                                          businessCustomer: businessCustomerData.id,
                                        });
                                      }
                                    }
                                  },
                                  value: values.businessCustomer
                                    ? values.businessCustomer
                                    : null
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              >
                                {businessCustomersData &&
                                  businessCustomersData.businessCustomers.map(
                                    (businessCustomer: IBookingBusinessCustomer) => {
                                      return (
                                        <MenuItem
                                          key={businessCustomer.id}
                                          value={businessCustomer.id}
                                        >
                                          {businessCustomer.businessName}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Field>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={InputField}
                                name={"authorizedSignatory"}
                                fullWidth
                                required
                                type="text"
                                select
                                label="Authorised Signatory"
                                inputProps={{
                                  onChange: (event: any) => {
                                    const authorizedSignatory = businessCustomer?.authorizedSignatories.find(
                                      (authSig) => {
                                        return authSig.id === event.target.value;
                                      }
                                    );
                                    if (!authorizedSignatory) {
                                      return;
                                    }
                                    setValues({
                                      ...values,
                                      authorizedSignatory: authorizedSignatory.id
                                    });
                                  },
                                  value: values.authorizedSignatory
                                    ? values.authorizedSignatory
                                    : null
                                }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                              >
                                {businessCustomer?.authorizedSignatories &&
                                  businessCustomer.authorizedSignatories.map(
                                    (signatory: any) => {
                                      return (
                                        <MenuItem
                                          key={signatory.id}
                                          value={signatory.id}
                                        >
                                          {`${signatory.firstName} ${signatory.lastName}`}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Field>
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      <Grid container item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={InputField}
                            name={"rateType"}
                            fullWidth
                            type="text"
                            select
                            label={"Rental Type"}
                            required
                            inputProps={{
                              onChange: (event: any) => {
                                rateTypes.forEach((rateType) => {
                                  const name = rateType.rateTypeName;
                                  if (
                                    name === String(event.target.value).toLowerCase()
                                  ) {
                                    setValues({
                                      ...values,
                                      billingCycleDuration: rateType.rateTypeDuration,
                                      billingCycleName: name,
                                      rateTypeDuration: rateType.rateTypeDuration,
                                      rateTypeName: name,
                                      vehicleGroups: []
                                    });
                                  }
                                });
                              },
                              value: values.rateTypeName
                                ? capitalizeFirstLetter(values.rateTypeName)
                                : "Daily"
                            }}
                          >
                            {rateTypes &&
                              rateTypes.map((rateType: any, key: number) => {
                                if (rateType.rateTypeName === RATE_TYPES.HOURLY) {
                                  const weeklyEndDate = getEndDateForBilling(values.pickupDateTime, RATE_TYPES.WEEKLY);
                                  if (values.dropoffDateTime && values.dropoffDateTime >= weeklyEndDate) {
                                    return;
                                  }
                                }
                                if ([RATE_TYPES.WEEKLY, RATE_TYPES.MONTHLY].includes(rateType.rateTypeName)) {
                                  const endDate = getEndDateForBilling(values.pickupDateTime, rateType.rateTypeName)
                                  const endDateStart = d.fromISO(endDate).startOf("day").toUTC().toISO();
                                  if (!(values.dropoffDateTime && values.dropoffDateTime >= endDateStart)) {
                                    return;
                                  }
                                }
                                return (
                                  <MenuItem
                                    value={capitalizeFirstLetter(
                                      rateType.rateTypeName
                                    )}
                                    key={key}
                                  >
                                    {capitalizeFirstLetter(rateType.rateTypeName)}
                                  </MenuItem>
                                );
                              })}
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid container item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Field
                            component={InputField}
                            label={"Pick-Up Location"}
                            id="pickup-location-select-outlined"
                            required
                            select
                            inputProps={{
                              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                setValues({
                                  ...values,
                                  pickupServiceLocation: event.target.value,
                                  vehicleGroups: []
                                });
                                if (isSharedFleet) {
                                  loadExternalVehicleGroups({
                                    variables: {
                                      args: {
                                        endDate: d.fromISO(values.dropoffDateTime || "").minus({ milliseconds: 1 }).toUTC().toISO(),
                                        startDate: d.fromISO(values.pickupDateTime).plus({ milliseconds: 1 }).toUTC().toISO(),
                                        vehicleId: dialogData.resource?.id,
                                        rateTypeDuration: values.rateTypeDuration,
                                        businessCustomer: businessCustomer && businessCustomer.id ? businessCustomer.id : "",
                                        longTerm: false,
                                        serviceLocation: event.target.value
                                      }
                                    }
                                  })
                                } else {
                                  loadVehicleGroups({
                                    variables: {
                                      args: {
                                        endDate: d.fromISO(values.dropoffDateTime || "").minus({ milliseconds: 1 }).toUTC().toISO(),
                                        startDate: d.fromISO(values.pickupDateTime).plus({ milliseconds: 1 }).toUTC().toISO(),
                                        vehicleId: dialogData.resource?.id,
                                        rateTypeDuration: values.rateTypeDuration,
                                        businessCustomer: businessCustomer && businessCustomer.id ? businessCustomer.id : "",
                                        longTerm: false,
                                        serviceLocation: event.target.value
                                      }
                                    }
                                  })
                                }
                              },
                              value: serviceLocations.length > 0 ? values.pickupServiceLocation : "",
                            }}
                            InputLabelProps={{
                              shrink: true
                            }}
                            name={"pickupServiceLocation"}
                            fullWidth
                          >
                            {serviceLocations && serviceLocations.length &&
                              [...serviceLocations].sort((a: IServiceLocation, b: IServiceLocation) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
                                .filter((sl) => {
                                  if (userState.currentOrganisation.crossLocationBookingEnabled && sl.branch) {
                                    return sl.branch.id === userState.currentBranch.id;
                                  } else {
                                    return sl;
                                  }
                                })
                                .map(
                                  (location: IServiceLocation, key: number) => {
                                    return (
                                      <MenuItem value={location.id} key={location.id}>
                                        {userState.currentOrganisation.crossLocationBookingEnabled ?
                                          `${location.name} - ${location.branch?.name}` : location.name}
                                      </MenuItem>
                                    );
                                  }
                                )}
                          </Field>
                        </FormControl>
                      </Grid>
                      {
                        differentDropOffLocation && (
                          <Grid container item xs={12} sm={6} md={4}>
                            <FormControl variant="outlined" fullWidth>
                              <Field
                                component={InputField}
                                id="dropoff-location-select-outlined"
                                required
                                select
                                label={"Drop-off Location"}
                                inputProps={{
                                  onChange: (event: any) => {
                                    setValues({
                                      ...values,
                                      dropoffServiceLocation: event.target.value
                                    });
                                    loadVehicleGroups({
                                      variables: {
                                        args: {
                                          endDate: d.fromISO(values.dropoffDateTime || "").minus({ milliseconds: 1 }).toUTC().toISO(),
                                          startDate: d.fromISO(values.pickupDateTime).plus({ milliseconds: 1 }).toUTC().toISO(),
                                          vehicleId: dialogData.resource?.id,
                                          rateTypeDuration: values.rateTypeDuration,
                                          businessCustomer: businessCustomer && businessCustomer.id ? businessCustomer.id : "",
                                          longTerm: false,
                                          serviceLocation: values.pickupServiceLocation,
                                          dropoffServiceLocation: event.target.value
                                        }
                                      }
                                    })
                                  },
                                  value: serviceLocations && serviceLocations.length > 0 ? values.dropoffServiceLocation : ""
                                }}
                                name={"dropoffServiceLocation"}
                                fullWidth
                              >
                                {serviceLocations && serviceLocations.length &&
                                  serviceLocations.map(
                                    (location: IServiceLocation, key: number) => {
                                      return (
                                        <MenuItem value={location.id} key={location.id}>
                                          {userState.currentOrganisation.crossLocationBookingEnabled ?
                                            `${location.name} - ${location.branch?.name}` : location.name}
                                        </MenuItem>
                                      );
                                    }
                                  )}
                              </Field>
                            </FormControl>
                          </Grid>
                        )
                      }

                      <Grid container item xs={12} sm={6} md={4}>
                        {
                          values.rateTypeDuration && (
                            <Field
                              component={InputField}
                              name={"vehicleGroup"}
                              fullWidth
                              required
                              type="text"
                              select
                              label="Vehicle Group"
                              inputProps={{
                                onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                                  const selectedVehicleGroup = vehicleGroups.find((vg) => {
                                    return vg.id === event.target.value
                                  })
                                  if (selectedVehicleGroup) {
                                    const basePrice = selectedVehicleGroup.basePrices.find((bp) => bp.rateTypeDuration === values.rateTypeDuration)
                                    if (basePrice?.applicableAmount) {
                                      setRentalPrice(basePrice.applicableAmount)
                                    } else {
                                      setRentalPrice(basePrice?.rate || 0)
                                    }
                                    if (basePrice && dialogData.resource?.id && selectedVehicleGroup.id) {
                                      setValues({
                                        ...values,
                                        vehicleGroups: [{
                                          vehicleIds: [dialogData.resource.id],
                                          name: selectedVehicleGroup.name,
                                          count: 1,
                                          baseRate: basePrice.rate,
                                          vehicleGroup: selectedVehicleGroup.id,
                                          ...(basePrice.priceRule && { priceRule: basePrice.priceRule }),
                                          ...(basePrice.priceRuleAmount && { priceRuleAmount: basePrice.priceRuleAmount }),
                                          ...(basePrice.priceRuleName && { priceRuleName: basePrice.priceRuleName }),
                                          ...(basePrice.priceRuleOperator && { priceRuleOperator: basePrice.priceRuleOperator }),
                                          ...(basePrice.priceRuleValueType && { priceRuleValueType: basePrice.priceRuleValueType }),
                                          ...(basePrice.priceRuleTriggerType && { priceRuleTriggerType: basePrice.priceRuleTriggerType }),
                                          deposit: selectedVehicleGroup.deposit,
                                          mileageLimit: basePrice.mileageLimit,
                                          pricePerExtraMile: basePrice.pricePerExtraMile,
                                          unlimitedMileage: basePrice.unlimitedMileage ? basePrice.unlimitedMileage : false
                                        }]
                                      })
                                    }
                                  }
                                },
                                value: values.vehicleGroups.length && values.vehicleGroups[0].vehicleGroup
                              }}
                              InputLabelProps={{
                                shrink: true
                              }}
                            >
                              {
                                !(vehicleGroupsLoading || externalVehicleGroupsLoading) ?
                                  vehicleGroups.length > 0 ?
                                    vehicleGroups.map(
                                      (vehicleGroup, index: number) => {
                                        const basePrice = vehicleGroup.basePrices.find((bs) => bs.rateTypeDuration === values.rateTypeDuration)
                                        if (basePrice) {
                                          const applicableAmount = basePrice.applicableAmount ?? basePrice.rate;
                                          if (applicableAmount) {
                                            return (
                                              <MenuItem key={index} value={vehicleGroup.id}>
                                                {`${vehicleGroup.name} - ${basePrice.priceRuleName ? `New Rate(${basePrice.priceRuleName}) - ` : 'Rate - '}`}
                                                {toCurrency(applicableAmount, currency, locale)}
                                              </MenuItem>
                                            );
                                          }
                                        }
                                      }
                                    ) : <MenuItem disabled>Group Not Found</MenuItem>
                                  : <MenuItem><CircularProgress /></MenuItem>
                              }
                            </Field>
                          )
                        }
                      </Grid>
                      <Grid container item xs={12} spacing={2}>
                        <Grid container item xs={12} md={3}>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={differentDropOffLocation}
                                  onChange={() =>
                                    setDifferentDropOffLocation(
                                      !differentDropOffLocation
                                    )
                                  }
                                  value="differentDropOffLocation"
                                  color="secondary"
                                />
                              }
                              label={<Typography variant="h5">{"Return To Different Location"}</Typography>}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FormControl>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.tba}
                                  onChange={() => {
                                    setValues({
                                      ...values,
                                      tba: !values.tba
                                    });

                                  }}
                                  value="tba"
                                  color="secondary"
                                />
                              }
                              label={
                                <Typography variant={"h5"}>
                                  {"Is TBA?"}
                                </Typography>
                              }
                            />
                          </FormControl>
                        </Grid>
                        {
                          values.tba && (
                            <Grid item xs={6} sm={2}>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={values.autoRenewal}
                                      onClick={() => {
                                        setValues({
                                          ...values,
                                          autoRenewal: !values.autoRenewal
                                        });
                                      }}
                                      name="autoRenewal"
                                      color="secondary"
                                    />
                                  }
                                  label={
                                    <Typography variant={"h5"}>
                                      {"Auto Renewal"}
                                    </Typography>
                                  }
                                />
                              </FormControl>
                            </Grid>
                          )
                        }
                        {
                          dialogData.resource?.pcoNumber && (
                            <Grid item xs={12} md={2}>
                              <FormControl>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={dialogData.resource?.pcoNumber ? true : false}
                                      value="pcoNumber"
                                      color="secondary"
                                      disabled={dialogData.resource?.pcoNumber ? true : false}
                                    />
                                  }
                                  label={
                                    <Typography variant={"h5"}>
                                      {"PCO Rental"}
                                    </Typography>
                                  }
                                />
                              </FormControl>
                            </Grid>
                          )
                        }
                      </Grid>
                      {
                        values.vehicleGroups &&
                        values.vehicleGroups.length > 0 &&
                        values.vehicleGroups.map((vg: IBookingVehicleGroup) => {
                          return (
                            <Grid item xs={7}>
                              <Typography variant={"h3"}>PRICE SPECIFICATION</Typography>
                              <Box mt={1}></Box>
                              <Grid container item xs={12} >
                                <Grid item xs={12}><Typography style={{ fontSize: 14 }} variant="body1">{`Rental (${values.vehicleGroups.length} X ${toCurrency(rentalPrice, currency, locale)}) \u00A0 = \u00A0  ${toCurrency(rentalPrice, currency, locale)}`} </Typography></Grid>
                                {
                                  locationSurchargeData &&
                                  locationSurchargeData.length > 0 &&
                                  locationSurchargeData.map(((surcharge: ILocationTaxSurcharge) => {
                                    return (
                                      <Grid item xs={12}>
                                        <Typography style={{ fontSize: 14, textTransform: "capitalize" }} variant="body1">
                                          {surcharge.name} = {surcharge.type === "PERCENTAGE" ? toCurrency(rentalPrice * surcharge.value / 100, currency, locale) : toCurrency(surcharge.value, currency, locale)}
                                        </Typography>
                                      </Grid>
                                    )
                                  }))
                                }
                                {
                                  oneWayRentalFee && oneWayRentalFee.amount > 0 && (
                                    <Grid item xs={12}>
                                      <Typography style={{ fontSize: 14 }} variant="body1"> One Way Fee = {toCurrency(oneWayRentalFee.amount, currency, locale)}</Typography>
                                    </Grid>
                                  )
                                }
                                {
                                  vg.unlimitedMileage && (
                                    <Grid item xs={12}><Typography style={{ fontSize: 14 }} variant="body1">{`Mileage Limit \u00A0  = \u00A0  Unlimited Mileage`}</Typography></Grid>
                                  )
                                }
                                {
                                  vg.mileageLimit > 0 && (
                                    <Grid item xs={12}><Typography style={{ fontSize: 14 }} variant="body1">{`Mileage Limit \u00A0  = \u00A0  ${getMileageLimitText(vg.mileageLimit, values.rateTypeName, distanceUnit)}`}</Typography></Grid>

                                  )
                                }
                              </Grid>
                            </Grid>
                          )
                        })
                      }
                      <Grid item xs={12} >
                        <Typography variant="body1"><strong>Note:</strong> Vehicle Group may not show due to vehicle preparation time. Close the pop-up and try again with later time period.</Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Fab
                          variant="extended"
                          size="medium"
                          style={{ marginBottom: '8px' }}
                          type={"submit"}
                          disabled={activateProceedButton}
                        >
                          PROCEED
                        </Fab>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Form>
        )}
      </Formik>
    </React.Fragment >
  );
}